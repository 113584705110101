import axios from "axios";
import { BASE_API_URL, DOMAIN } from "../config";
import { showAlert } from "../utils/alert";

const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});

export const changePhoto = async () => {
  const form = new FormData();
  form.append("photo", document.getElementById("upload-photo").files[0]);
  form.append("bio", document.getElementById("bio-form").value);
  if (document.getElementsByName("phone")[0].value) {
    const phone = document.getElementsByName("phone")[0].value;
    form.append("phone", phone.replace(/(\+|\(|\)|-|\ )/g, ""));
  }
  if (document.getElementsByName("bdate")[0].value) {
    const date = document.getElementsByName("bdate")[0].value;
    const bdate =
      date.split("/").length === 3
        ? `${date.split("/")[2]}-${date.split("/")[0]}-${date.split("/")[1]}`
        : "";
    form.append("bdate", bdate);
  }
  await updateSettings(form, "data");
};

export const changePassword = async () => {
  const data = {
    password: document.getElementById("oldPassword").value,
    newPassword: document.getElementById("newPassword").value,
    newPasswordConfirm: document.getElementById("newPassword").value,
  };
  await updateSettings(data, "password");
};
/**
 *
 * @param {FormData} data is an Object to update user information.
 * @param {("password"|"data")} type Type of data information.
 */
const updateSettings = async (data, type) => {
  const url =
    type === "password" ? "/users/updateMyPassword" : "/users/updateMe";
  const elementShow =
    type === "password" ? "#response-title-2" : "#response-title";
  try {
    const res = await fetch.patch(url, data);
    if (res.data.status === "success") {
      showAlert("success", "Your profile is updated.", {
        msgType: "block",
        position: "center",
        elementShow,
      });
      window.setTimeout(() => {
        location.assign(`/profile`);
      }, 3000);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message, {
      msgType: "text",
      position: "center",
      elementShow,
    });
  }
};
