import { NOTIFY_KEY, fetch } from "../config";
const pusher = new Pusher(`${NOTIFY_KEY}`, {
  cluster: "us2",
});

export const notify = async (channelName, eventName) => {
  toastr.options = {
    closeButton: false,
    debug: false,
    escapeHtml: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "5000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };
  const channel = pusher.subscribe(channelName);
  channel.bind(`${eventName}`, async (data) => {
    toastr.success(data);
    await updateNotificationItems();
  });
};

const updateNotificationItems = async () => {
  const notificationShowElem = document.getElementById("notificationShow");
  const notificationNumber = notificationShowElem.querySelector(
    ".notification-number"
  );
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };
  try {
    const res = await fetch.get(`notifications/unread`);
    if (res.data.status === "success") {
      console.log(res.data.data.data);
      notificationNumber.textContent = res.data.results;
      let notifyIcon = "";
      const notification = res.data.data.data[res.data.results - 1];
      if (notification.message.image !== undefined) {
        notifyIcon = `
        <div class='notify-icon'>
          <img class='img-fluid rounded-circle src=/img/notifications/${notification.message.image}, alt='notify-image'>
        </div>
        `;
      } else {
        notifyIcon = `
        <div class='notify-icon bg-primary'>
          <i class=${notification.message.icon}></i>
        </div>
        `;
      }
      const itemHTML = `
      <a class='dropdown-item notify-item active' href='/inbox'>
        ${notifyIcon}
        <p class='notify-details'>${notification.message.subject}</p>
        <p class='text-muted mb-0 user-msg'>
          <small>${notification.message.content}</small>
          <small class='diff-time'>${Intl.DateTimeFormat(
            "en-US",
            options
          ).format(new Date(notification.createdAt))}</small>
        </p>
      </a>
      `;
      const itemPosition = notificationShowElem.querySelector(
        ".slimscroll.noti-scroll"
      );
      console.log(itemPosition);
      console.log("html-insert:", itemHTML);
      itemPosition.insertAdjacentHTML("afterbegin", itemHTML);
    }
  } catch (err) {
    console.log(err);
  }
};
