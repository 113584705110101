import axios from "axios";
import { BASE_API_URL, DOMAIN } from "../config";
import { showAlert } from "../utils/alert";

const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});
const bookingInvoice = {};
export const loadPartialPayment = (partialPayment) => {
  const insertedElement = `
  <div class="form-group row partial-detail" id="partial-payment-${partialPayment.rowNumber}">
    <div class="col-sm-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">${partialPayment.rowNumber}</span>
        </div>
        <input class="partial-name text-muted form-control float-left" type="text" placeholder="Title of payment" required>
      </div>
    </div>
    <label class="col-sm-1 col-form-label">Price</label>
    <div class="col-sm-4">
      <div class="input-group mb-3">
        <input class="partial-price text-muted form-control float-left" type="text" maxlength="22" required>
        <div class="input-group-append">
          <span class="input-group-text">$</span>
        </div>
        <a class="ml-2 delete-payment" herf="#"><i class="mdi mdi-trash-can-outline text-danger mr-1 font-20"></i></a>
      </div>
    </div>
  </div>
  `;

  const partialNode = document.querySelectorAll(".partial-detail");
  if (partialNode.length === 1) {
    const deleteAlink = `
    <a class="ml-2 delete-payment" herf="#">
      <i class="mdi mdi-trash-can-outline text-danger mr-1 font-20"></i>
    </a>
  `;
    partialNode[0]
      .querySelector(".input-group-append")
      .insertAdjacentHTML("afterend", deleteAlink);
  }
  partialNode[partialPayment.rowNumber - 2].insertAdjacentHTML(
    "afterend",
    insertedElement
  );
};

export const deletePartialPayment = (el) => {
  const elementId = el.parentElement.parentElement.parentElement.id;
  document.getElementById(elementId).remove();
  const partialNode = document.querySelectorAll(".partial-detail");
  partialNode.forEach((item, index) => {
    item.querySelector("span.input-group-text").textContent = index + 1;
  });
  if (partialNode.length === 1) {
    partialNode[0].querySelector("a.delete-payment").remove();
  }
  subtotalCalc(el);
  return partialNode.length;
};

export const subtotalCalc = (element) => {
  if (element) {
    const allPartialPriceEl = document.querySelectorAll("input.partial-price");
    let subTotal = 0;
    allPartialPriceEl.forEach(
      (el) => (subTotal += parseFloat(el.value ? el.value : 0))
    );
    document.getElementById(
      "sum-partial-price"
    ).childNodes[0].textContent = `Total Price: ${subTotal
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} $`;
    document.getElementById("total-price").value = subTotal
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const submitSuggestion = async () => {
  const bookingId = document.getElementById("booking-id").dataset.bookingId;
  const summary = document.getElementById("contractor-summary").value;
  const conditional = false;
  const duration = parseInt(document.getElementById("duration").value);
  const isPartial = document.querySelector("#isPartialPayment>input").checked;
  const action = document.getElementById("suggestRequestForm").dataset.action;
  const contractors = {};
  const paymentConditions = [];
  if (!isPartial) {
    paymentConditions.push({
      name: "You should pay after done.",
      price: document.getElementById("total-price").value,
    });
  } else {
    const allPartialPriceEl = document.querySelectorAll("input.partial-price");
    allPartialPriceEl.forEach((el) => {
      paymentConditions.push({
        price: parseFloat(el.value),
        name: el.parentElement.parentElement.parentElement.querySelector(
          "input.partial-name"
        ).value,
      });
    });
  }
  contractors.summary = summary;
  contractors.conditional = conditional;
  contractors.duration = duration;
  contractors.paymentConditions = paymentConditions;
  contractors.action = action;
  const elementShow = document.getElementById("response-title");
  try {
    const res = await fetch.patch(`bookings/${bookingId}`, { contractors });
    if (res.data.status === "success") {
      showAlert("success", "Your suggestion is successfully submited.", {
        msgType: "block",
        position: "center",
        elementShow,
      });
      window.setTimeout(() => {
        location.assign(`/request-select`);
      }, 3000);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message, {
      msgType: "block",
      position: "center",
      elementShow,
    });
    window.setTimeout(() => {
      location.reload();
    }, 3000);
  }
};

export const deleteSuggestRequest = async (bookingId) => {
  const contractors = { action: "edit" };
  const elementShow = document.getElementById("response-title");
  try {
    const res = await fetch.patch(`bookings/${bookingId}`, { contractors });
    if (res.data.status === "success") {
      showAlert("success", "Your suggestion is successfully deleted.", {
        msgType: "block",
        position: "center",
        elementShow,
      });
      window.setTimeout(() => {
        location.assign(`/request-select`);
      }, 3000);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message, {
      msgType: "block",
      position: "center",
      elementShow,
    });
    window.setTimeout(() => {
      location.reload();
    }, 3000);
  }
};

export const acceptRequest = async (bookingId, action) => {
  const elementShow = document.getElementById("response-title");
  try {
    const winnerContractor = {};
    winnerContractor.action = action;
    winnerContractor.acceptedDate =
      action === "accepted" ? `${new Date()}` : undefined;
    const res = await fetch.patch(`bookings/${bookingId}`, {
      winnerContractor,
    });
    if (res.data.status === "success") {
      showAlert("success", `The request successfully ${action}.`, {
        msgType: "block",
        position: "center",
        elementShow,
      });
    }
    window.setTimeout(() => {
      location.assign(`/request-select`);
    }, 3000);
  } catch (err) {
    showAlert("danger", err.response.data.message, {
      msgType: "block",
      position: "center",
      elementShow,
    });
  }
};

export const sendBill = async () => {
  const contractorId = document.getElementById(
    "contractor-request-payment-form"
  ).dataset.contractor;
  const paymentId = document.getElementById("payment-name").dataset.paymentId;
  const bookingId = document.getElementById("booking-id").dataset.bookingId;
  const elementShow = document.getElementById("response-title");
  try {
    const res = await fetch.patch(
      `bookings/${bookingId}/contractor/${contractorId}/paymentConditions/${paymentId}`,
      {
        status: "requested",
      }
    );
    if (res.data.status === "success") {
      showAlert("success", "Your billing successfully sent.", {
        msgType: "block",
        position: "center",
        elementShow,
      });
    }
    window.setTimeout(() => {
      location.assign(`/request-select`);
    }, 3000);
  } catch (err) {
    showAlert("danger", err.response.data.message, {
      msgType: "block",
      position: "center",
      elementShow,
    });
    window.setTimeout(() => {
      location.reload();
    }, 3000);
  }
};

export const clearInvoiceItems = () => {
  const invoice = document.getElementById("contractor-invoice");
  invoice.querySelector("tbody").textContent = "";
  const invoiceItemForm = `
  <hr class='border border-4'>
  <form class='row' id='manual-invoice-item'>
    <div class='col-sm-5'>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Desc.</span>
        </div>
        <input id="description" class="text-muted form-control float-left" type="text" placeholder="Description" required>
      </div>
    </div>
    <div class='col-sm-2'>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">#</span>
        </div>
        <input id="quantity" class="text-muted form-control float-left" type="number" min="1" value='1' required>
      </div>
    </div>
    <div class='col-sm-4'>
      <div class="input-group mb-3">
        <input id="price" class="text-muted form-control float-left" type="number" required>
        <div class="input-group-append">
          <span class="input-group-text">$</span>
        </div>
      </div>
    </div>
    <div class='col-sm-1 text-success' id='add-invoice-item-btn'>
      <i class='mdi mdi-pencil-plus-outline font-20'></i>
    </div>
  </form>
  `;
  const manualInvoice = document.getElementById("manual-invoice-item");
  if (!manualInvoice) {
    document
      .getElementById("manual-form")
      .insertAdjacentHTML("afterbegin", invoiceItemForm);
    document.getElementById("invoice-submit").classList.add("d-none");
    bookingInvoice.subtotal = document.getElementById(
      "invoice-subtotal-result"
    ).dataset.subtotal;
    bookingInvoice.tax = JSON.parse(
      document.getElementById("invoice-subtotal-result").dataset.tax
    );
    const caution = document.getElementById("caution-response");
    const message = `
      <small class='text-pink'>
    Please consider that the total price of your invoice must be equal to $$$ ${currencyFormat(
      bookingInvoice.subtotal
    )}
    </small>`;
    caution.insertAdjacentHTML("afterbegin", message);
    invoiceResultInsert();
  }
};

export const addInvoiceItem = (title, price, quantity) => {
  const invoice = document.getElementById("contractor-invoice");
  const rowNum = document.querySelectorAll(".item-number").length + 1;
  const tRecord = `
    <tr id='table-row-${rowNum}'>
      <td class='item-number'>
      ${rowNum}
      </td>
      <td class='item-title'>
      ${title}
      </td>
      <td class='item-quantity'>
      ${quantity}
      </td>
      <td class='item-price'>
      $$$ ${currencyFormat(price)}
      </td>
      <td class='total-price'>
        <div class='row'>
          <div class='col-sm-10 row-price'>$$$ ${currencyFormat(
            price * quantity
          )}</div>
          <div class='col-sm-2 delete-row'>
            <a href='#' class='text-danger ml-2'>
              <i class='mdi mdi-trash-can-outline font-18'></i>
            </a>
          </div>
        </div>
      </td>
    </tr>
  `;
  document.getElementById("description").value = "";
  document.getElementById("quantity").value = "1";
  document.getElementById("price").value = "";
  invoice.querySelector("tbody").insertAdjacentHTML("beforeend", tRecord);
  invoiceResultInsert();
};
/**
 * Convert Number to Currency Format
 * @param {Number} Number The number that you want to change in currency formet
 */
const currencyFormat = (Number) => {
  return (Math.round(Number * 100) / 100)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const clearInvoiceOneItem = (invoiceEl) => {
  const tableRow = invoiceEl.closest("tr").id;
  const tbody = document.querySelector("#contractor-invoice>table>tbody");
  tbody.querySelector(`#${tableRow}`).remove();
  document
    .querySelectorAll(".item-number")
    .forEach((el, index) => (el.textContent = index + 1));
  invoiceResultInsert();
};

const invoiceResultInsert = () => {
  const totalPriceNodes = document.querySelectorAll(".row-price");
  let price = 0;
  for (let item of totalPriceNodes) {
    price += parseFloat(item.textContent.split("$$$ ")[1].replaceAll(",", ""));
  }
  const serviceFee = price * 0.07;
  const PST = (serviceFee + price) * (bookingInvoice.tax.taxValue.PST / 100);
  const GST = (serviceFee + price) * (bookingInvoice.tax.taxValue.GST / 100);
  const total = serviceFee + price + PST + GST;
  document.querySelector(
    "#invoice-subtotal>b"
  ).textContent = `Sub-total:  ${currencyFormat(price)}`;
  document.getElementById(
    "invoice-sevice-fee"
  ).textContent = `Service Fee: ${currencyFormat(serviceFee)}`;
  document.getElementById("invoice-PST").textContent = `PST ${
    bookingInvoice.tax.taxValue.PST
  }%: ${currencyFormat(PST)}`;
  document.getElementById("invoice-GST").textContent = `GST ${
    bookingInvoice.tax.taxValue.GST
  }%: ${currencyFormat(GST)}`;
  document.getElementById("invoice-total-price").textContent = `${
    bookingInvoice.tax.currency
  } ${currencyFormat(total)}`;
  if (price === parseFloat(bookingInvoice.subtotal)) {
    document.getElementById("invoice-submit").classList.remove("d-none");
    document.getElementById("caution-response").classList.add("d-none");
  } else {
    document.getElementById("caution-response").classList.remove("d-none");
    document.getElementById("invoice-submit").classList.add("d-none");
  }
  return price;
};

export const submitContractorInvoice = async () => {
  if (bookingInvoice.tax === undefined) {
    bookingInvoice.subtotal = document.getElementById(
      "invoice-subtotal-result"
    ).dataset.subtotal;
    bookingInvoice.tax = JSON.parse(
      document.getElementById("invoice-subtotal-result").dataset.tax
    );
  }
  const elementShow = document.getElementById("response-title");
  const bookingId = document.getElementById("booking-id").dataset.bookingId;
  const items = [];
  const serviceFee = (bookingInvoice.subtotal * 0.07).toFixed(2);
  document.querySelectorAll("tbody>tr").forEach((el) => {
    items.push({
      title: el.querySelector(".item-title").textContent.trim(),
      quantity: parseFloat(el.querySelector(".item-quantity").textContent),
      price: parseFloat(
        el
          .querySelector(".item-price")
          .textContent.split("$$$ ")[1]
          .replaceAll(",", "")
      ),
    });
  });
  const invoice = {
    items,
    tax: {
      GST: bookingInvoice.tax.taxValue.GST,
      PST: bookingInvoice.tax.taxValue.PST,
    },
    serviceFee,
    currency: bookingInvoice.tax.currency,
  };
  try {
    const res = await fetch.patch(`bookings/${bookingId}`, {
      invoice,
    });
    if (res.data.status === "success") {
      showAlert("success", "Your invoice is successfully submitted.", {
        msgType: "block",
        position: "center",
        elementShow,
      });
      window.setTimeout(() => {
        location.assign(`/request-select`);
      }, 3000);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message, {
      msgType: "block",
      position: "center",
      elementShow,
    });
    window.setTimeout(() => {
      location.reload();
    }, 3000);
  }
};
