import axios from "axios";
import { BASE_API_URL, DOMAIN } from "../config";
import { showAlert } from "../utils/alert";
import Element from "../utils/Element";

const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});

//NOTE: Add Card Data
export const updateCardInfo = async () => {
  const elementShow = document.getElementById("response-title");
  const userId = document.getElementById("credit-card-submitting").dataset
    .userId;
  const cardNumber = document.getElementById("cardNumber").value
    ? document.getElementById("cardNumber").value
    : "";
  const cardHolder = document.getElementById("cardName").value
    ? document.getElementById("cardName").value
    : "";
  const expMonth = document.getElementById("cardMonth").value
    ? parseInt(document.getElementById("cardMonth").value)
    : "";
  const expYear = document.getElementById("cardYear").value
    ? parseInt(document.getElementById("cardYear").value)
    : "";

  let imageName = "";
  if (document.querySelector("img.credit-card-item__typeImg").src) {
    const imageSource = document.querySelector("img.credit-card-item__typeImg")
      .src;
    const lastPartImage = imageSource.split("/")[
      imageSource.split("/").length - 1
    ];
    imageName = lastPartImage.split(".")[0];
  }

  const cardBrand = imageName ? imageName : "";
  if (!cardNumber || !cardHolder || !expMonth || !expYear || !cardBrand) {
    showAlert("danger", "Please fill all items.", {
      msgType: "block",
      position: "center",
      elementShow,
    });
  } else {
    try {
      const res = await fetch.patch(`users/${userId}/paymentCard`, {
        paymentCards: {
          cardNumber,
          cardBrand,
          cardHolder,
          expMonth,
          expYear,
        },
        action: "add",
      });
      if (res.data.status === "success") {
        showAlert("success", "Card information successfully added.", {
          msgType: "block",
          position: "center",
          elementShow,
        });
        window.setTimeout(() => {
          location.assign(`/myCards`);
        }, 3000);
      }
    } catch (err) {
      showAlert("danger", err.response.data.message, {
        msgType: "block",
        position: "center",
        elementShow,
      });
    }
  }
};

//NOTE: Remove Card Data
export const cardUpdate = async (userId, cardId, action, response) => {
  try {
    const res = await fetch.patch(`users/${userId}/paymentCard`, {
      action,
      cardId,
    });
    if (res.data.status === "success") {
      const msg = `Your card information is successfuly removed.`;
      showAlert("success", msg, {
        msgType: "text",
        position: "center",
        elementShow: response,
      });
      window.setTimeout(() => {
        location.assign(`/myCards`);
      }, 3000);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message, {
      msgType: "text",
      position: "center",
      elementShow: response,
    });
  }
};

export const deleteAction = (deleteElement, state) => {
  if (deleteElement.childNodes.length === 1 && state === "show") {
    const actions = `
    <li class='list-group-item'>
      <div class='row text-danger card-confirm-delete'>
        <div class='col-sm-2 font-20 text-left'><i class='mdi mdi-close-circle'></i></div>
        <div class='col-sm-10'>Remove card</div>
      </div>
    </li>
    <li class='list-group-item'>
      <div class='row text-warning card-cancel-delete'>
        <div class='col-sm-2 font-20 text-left'><i class='mdi mdi-check-circle'></i></div>
        <div class='col-sm-10'>Cancel</div>
      </div>
    </li>
    `;
    deleteElement.insertAdjacentHTML("beforeend", actions);
  }
  if (deleteElement.childNodes.length > 1 && state === "hide") {
    const listItems = deleteElement.childNodes.length;
    for (let i = 1; i < listItems; i++) {
      deleteElement.removeChild(deleteElement.lastChild);
    }
  }
};

export const cardChangeAction = async (element) => {
  const objectAction = element.closest("ul.card-action");
  if (objectAction) {
    deleteAction(objectAction, "show");
    const userId = document.getElementById("user-info").dataset.id;
    const cardId = objectAction.parentElement.id.split("-")[3];
    if (objectAction.childNodes.length > 1) {
      if (
        element.closest("div.card-cancel-delete") ===
        objectAction.querySelector("div.card-cancel-delete")
      ) {
        deleteAction(objectAction, "hide");
      } else if (
        element.closest("div.card-confirm-delete") ===
        objectAction.querySelector("div.card-confirm-delete")
      ) {
        // process
        const el = new Element();
        const pElement = element
          .closest("li.list-group-item")
          .parentElement.firstChild.querySelector(".change-response-title");
        const oldText = el.createSpinnerPragraph(pElement, "Processing", {
          position: "beforeend",
        });
        try {
          await cardUpdate(userId, cardId, "remove", pElement);
          pElement.textContent = oldText;
          pElement.classList.toggle("d-none");
        } catch (err) {
          showAlert("danger", err.message, {
            msgType: "text",
            position: "center",
            elementShow: pElement,
          });
        }
      }
    }
  }
};

export const showCard = (element) => {
  const cardData = element.closest("li>div.user-list-item")
    ? element.closest("li>div.user-list-item").dataset.card
    : undefined;

  if (cardData) {
    const selectedElement = element.closest("li>div.user-list-item");

    document.getElementById("cardName").value = selectedElement.querySelector(
      "p.card-holder"
    ).dataset.cardHolder;
    const expDate = selectedElement
      .querySelector("small.card-exp")
      .textContent.split("Exp: ")[1];

    document.getElementById("cardMonth").value =
      expDate.split("/")[0] < 10
        ? `0${expDate.split("/")[0]}`
        : `${expDate.split("/")[0]}`;
    document.getElementById("cardYear").value = `${expDate.split("/")[1]}`;
    document.getElementById("cardCvv").value = "***";
    document.getElementById("cardNumber").value = cardData;
  }
};
