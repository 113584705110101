/**eslint-disable */
import axios from "axios";

export const showMap = () => {
  mapboxgl.accessToken =
    "pk.eyJ1IjoiYW1pcnNhZWVkIiwiYSI6ImNrZmVlZ3YyZzA0NmIzMHF2NXE5NTQyNmsifQ.ql_Kz5L2DLkFCrumahyBbA";

  var map = new mapboxgl.Map({
    container: "map",
    style: "mapbox://styles/amirsaeed/ckfehtf1y0aqm19nk1wjvfcx6",
    center: [-79.4163, 43.70011],
    zoom: 13,
  });
  map.addControl(new mapboxgl.NavigationControl());
  // Add geolocate control to the map.
  map.addControl(
    new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    })
  );
  map.on("click", (e) => {
    // create DOM element for the marker
    var preEl = document.getElementById("marker");
    if (preEl) preEl.parentElement.removeChild(preEl);
    var el = document.createElement("div");
    el.id = "marker";

    // create the marker
    new mapboxgl.Marker(el).setLngLat(e.lngLat).addTo(map);
    document.getElementById("map").dataset.location = [
      e.lngLat.lng,
      e.lngLat.lat,
    ];
    getAddress(e.lngLat.lat, e.lngLat.lng);
  });
};

export const getAddress = async (lat, lng) => {
  document.getElementById("address").value = "";
  document.getElementById("postalcode").value = "";
  document.getElementById("description").value = "";
  document.getElementById("province").value = "";
  document.getElementById("title").value = "";
  if (document.getElementById("map")) {
    document.getElementById("map").dataset.location = [`${lng},${lat}`];
  }
  const res = await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json`,
    {
      params: {
        access_token: mapboxgl.accessToken,
      },
    }
  );
  // console.log(res);
  if (res.status === 200) {
    for (const d of res.data.features) {
      if (
        d.place_type[0] === "address" ||
        d.place_type[0] === "poi" ||
        d.place_type[0] === "locality"
        // || d.place_type[0] === "place"
      ) {
        document.getElementById("address").value = d.place_name;
      }
      if (d.place_type[0] === "postcode") {
        document.getElementById("postalcode").value = d.text;
      }
      if (
        d.place_type[0] === "address" ||
        d.place_type[0] === "poi" ||
        d.place_type[0] === "locality"
        // || d.place_type[0] === "place"
      ) {
        document.getElementById("description").value = d.text;
      }
      if (d.place_type[0] === "region") {
        document.getElementById("province").value = d.text;
      }
    }
  }
};
