import axios from "axios";
import { getAddress } from "../utils/mapbox";
import { BASE_API_URL, DOMAIN } from "../config";
import { showAlert } from "../utils/alert";
import Element from "../utils/Element";

const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});

export const locationUpdate = async (
  userId,
  locationData,
  action,
  response
) => {
  try {
    const res = await fetch.patch(`users/${userId}/locationHandler`, {
      action,
      location: locationData,
    });
    if (res.data.status === "success") {
      const msg =
        action === "changeCurrentLocation"
          ? "Current location is successfuly changed."
          : `Your address is successfuly ${action}ed.`;
      showAlert("success", msg, {
        msgType: "text",
        position: "center",
        elementShow: response,
      });
      window.setTimeout(() => {
        location.assign(`/locations`);
      }, 3000);
    }
  } catch (error) {
    showAlert("danger", res.data.error.message, {
      msgType: "text",
      position: "center",
      elementShow: response,
    });
  }
};

export const deleteAction = (deleteElement, state) => {
  if (deleteElement.childNodes.length === 1 && state === "show") {
    const actions = `
    <li class='list-group-item'>
      <div class='row text-danger location-confirm-delete'>
        <div class='col-sm-2 font-20 text-left'><i class='mdi mdi-close-circle'></i></div>
        <div class='col-sm-10'>Remove location</div>
      </div>
    </li>
    <li class='list-group-item'>
      <div class='row text-success location-current-change'>
        <div class='col-sm-2 font-20 text-left'><i class='mdi mdi-map-marker-check'></i></div>
        <div class='col-sm-10'>Set current location</div>
      </div>
    </li>
    <li class='list-group-item'>
      <div class='row text-warning location-cancel-delete'>
        <div class='col-sm-2 font-20 text-left'><i class='mdi mdi-check-circle'></i></div>
        <div class='col-sm-10'>Cancel</div>
      </div>
    </li>
    `;
    deleteElement.insertAdjacentHTML("beforeend", actions);
  }
  if (deleteElement.childNodes.length > 1 && state === "hide") {
    const listItems = deleteElement.childNodes.length;
    for (let i = 1; i < listItems; i++) {
      deleteElement.removeChild(deleteElement.lastChild);
    }
    // deleteElement
    //   .querySelector(".change-response-title")
    //   .classList.toggle("d-none");
  }
  // if(elements)
};

export const showLocation = async (element) => {
  const locationData = element.closest("li>div.user-list-item")
    ? element
        .closest("li>div.user-list-item")
        .dataset.location.replace(/\[|\]/g, "")
        .split(",")
    : undefined;

  if (locationData) {
    try {
      await getAddress(locationData[0], locationData[1]);
      document.getElementById("title").value = element
        .closest("li")
        .querySelector("[data-location-title]").dataset.locationTitle;
    } catch (err) {
      showAlert(
        "danger",
        "There was an error to load location. please try again later.",
        {
          msgType: "text",
          position: "center",
          elementShow: "#response-title",
        }
      );
    }
  }
};

export const locationChangeAction = async (element) => {
  const locationAction = element.closest("ul.location-action");
  if (locationAction) {
    deleteAction(locationAction, "show");
    // locationAction
    //   .querySelector(".change-response-title")
    //   .classList.toggle("d-none");
    const userId = document.getElementById("user-info").dataset.id;
    const locationId = locationAction.parentElement.id.split("-")[3];
    if (locationAction.childNodes.length > 1) {
      if (
        element.closest("div.location-cancel-delete") ===
        locationAction.querySelector("div.location-cancel-delete")
      ) {
        deleteAction(locationAction, "hide");
        // locationAction
        //   .querySelector(".change-response-title")
        //   .classList.toggle("d-none");
      } else if (
        element.closest("div.location-confirm-delete") ===
        locationAction.querySelector("div.location-confirm-delete")
      ) {
        // process
        const el = new Element();
        const pElement = element
          .closest("li.list-group-item")
          .parentElement.firstChild.querySelector(".change-response-title");
        const oldText = el.createSpinnerPragraph(pElement, "Processing", {
          position: "beforeend",
        });
        try {
          await locationUpdate(userId, locationId, "remove", pElement);
          pElement.textContent = oldText;
          pElement.classList.toggle("d-none");
        } catch (err) {
          showAlert("danger", err.message, {
            msgType: "text",
            position: "center",
            elementShow: pElement,
          });
        }
      } else if (
        element.closest("div.location-current-change") ===
        locationAction.querySelector("div.location-current-change")
      ) {
        // process
        const el = new Element();

        const pElement = element
          .closest("li.list-group-item")
          .parentElement.firstChild.querySelector(".change-response-title");
        const oldText = el.createSpinnerPragraph(pElement, "Processing", {
          position: "beforeend",
        });
        try {
          await locationUpdate(
            userId,
            locationId,
            "changeCurrentLocation",
            pElement
          );
          pElement.textContent = oldText;
          pElement.classList.toggle("d-none");
        } catch (err) {
          showAlert("danger", err.message, {
            msgType: "text",
            position: "center",
            elementShow: pElement,
          });
        }
      }
    }
  }
};
