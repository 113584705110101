export default class Modal {
  /**
   *
   * @param {Element} modalElement The Modal Element
   */
  constructor(modalElement) {
    this.modalElement = modalElement;
    this.modalBody = document.querySelector(".modal-body");
    this.id = modalElement.id;
  }
  clearContent = () => {
    this.modalBody.textContent = "";
  };
  /**
   *
   * @param {("afterend"|"afterbegin"|"beforeend"|"beforebegin")} position The position that you want content inserted
   * @param {Strin} content The content
   */
  showContent = (position, content) => {
    this.modalBody.insertAdjacentHTML(position, content);
    $(`#${this.id}`).modal();
  };
}
