/* eslint-disable */
import axios from "axios";
import { showAlert } from "./utils/alert";
import { BASE_API_URL, DOMAIN } from "./config";

const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});
/**
 * Return jwt to login with email address.
 * @param {String} email The email address.
 * @param {password} password The password.
 */
export const loginEmail = async (email, password) => {
  try {
    const res = await fetch.post("users/login", {
      email,
      password,
    });
    if (res.data.status === "success") {
      showAlert("success", "Logged in is successfuly.");
      window.setTimeout(() => {
        location.assign("/dashboard");
      }, 1500);
    }
  } catch (err) {
    showAlert("danger", err.response?.data.message);
  }
};

/**
 * Return jwt to login with mobile number.
 * @param {String} mobile The mobile number.
 * @param {String} mobileCode The mobile code that contains 7 digits.
 */
export const loginMobile = async (mobile, mobileCode) => {
  try {
    const res = await fetch.post("users/loginMobile", {
      mobile,
      mobileCode,
    });
    if (res.data.status === "success") {
      showAlert("success", "Logged in is successfuly.");
      window.setTimeout(() => {
        location.assign("/dashboard");
      }, 1500);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message);
  }
};
/**
 * Return jwt to signup with mobile number.
 * @param {String} mobile The mobile number.
 * @param {String} mobileCode The mobile code that has 7 digits
 */
export const signupMobile = async (mobile, mobileCode) => {
  try {
    const res = await fetch.post("users/signupMobile", {
      mobile,
      mobileCode,
    });
    if (res.data.status === "success") {
      showAlert("success", "Signed up is successfuly.");
      window.setTimeout(() => {
        location.assign("/dashboard");
      }, 1500);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message);
  }
};
//NOTE: res.redirect("somewhere");
export const logout = async () => {
  try {
    const res = await fetch.get("users/logout");
    if (res.data.status === "success") {
      window.setTimeout(() => {
        location.assign("/");
      }, 1500);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message);
  }
};
/**
 * Return result for sending reset password email.
 * @param {String} email The email address.
 */
export const forgetPassword = async (email) => {
  try {
    const res = await fetch.post("users/forgotPassword", {
      email,
    });
    if (res.data.status === "success") {
      showAlert("success", "Reset password link was send to your email.");
      window.setTimeout(() => {
        location.assign(`/mailconfirmation/${email}`);
      }, 3000);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message);
  }
};
/**
 * Return result for resetting password.
 * @param {String} password The password.
 * @param {String} passwordConfirm The confirmation password.
 * @param {String} token The token that create for resetting password.
 */
export const resetPassword = async (password, passwordConfirm, token) => {
  try {
    const res = await fetch.patch(`users/resetPassword/${token}`, {
      password,
      passwordConfirm,
    });
    if (res.data.status === "success") {
      showAlert("success", "Your password is updated.");
      window.setTimeout(() => {
        location.assign(`/login`);
      }, 3000);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message);
  }
};
