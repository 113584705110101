import axios from "axios";
import { BASE_API_URL, DOMAIN } from "../config";
import { showAlert } from "../utils/alert";

const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});

/**
 * For user rating to contractor
 * @param {String} bookingId The booking Id for rating
 * @param {Number} rating The rating value
 */
export const submitRating = async (bookingId, rating) => {
  const elementShow = document.getElementById("response-title");
  try {
    const res = await fetch.patch(`bookings/${bookingId}/rating`, {
      rating,
    });
    if (res.data.status === "success") {
      showAlert("success", "Your rating is successfuly submited.", {
        msgType: "text",
        position: "left",
        elementShow,
      });
      window.setTimeout(() => {
        location.assign(`/myOrders`);
      }, 3000);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message, {
      msgType: "text",
      position: "center",
      elementShow,
    });
  }
};
