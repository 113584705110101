/* eslint-disable */
import axios from "axios";
import { showAlert } from "../utils/alert";
import { BASE_API_URL, DOMAIN } from "../config";

const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});
const data = {};
const wizardForm = {
  name: document.getElementById("fullname"),
  gender: document.getElementById("gender"),
  role: document.getElementById("role"),
  email: document.getElementById("emailaddress"),
  password: document.getElementById("password"),
  passwordConfirm: document.getElementById("passwordConfirm"),
  address: document.getElementById("address"),
  description: document.getElementById("description"),
  postalCode: document.getElementById("postalcode"),
  province: document.getElementById("province"),
  title: document.getElementById("title"),
};
export const nextValidate = (step) => {
  if (step === "second" && wizardForm.name.value && wizardForm.gender.value) {
    data.role = document.getElementById("inlineRadio1").checked
      ? document.getElementById("inlineRadio1").value.toLowerCase()
      : document.getElementById("inlineRadio2").value.toLowerCase();
    data.name = wizardForm.name.value;
    data.gender = wizardForm.gender.value;
  }
  if (
    step === "third" &&
    wizardForm.email.value &&
    wizardForm.password.value &&
    wizardForm.passwordConfirm.value
  ) {
    data.email = wizardForm.email.value;
    data.password = wizardForm.password.value;
    data.passwordConfirm = wizardForm.passwordConfirm.value;
  }
  if (
    step === "fourth" &&
    wizardForm.address.value &&
    wizardForm.description.value &&
    wizardForm.postalCode.value
  ) {
    const location = document.getElementById("map").dataset.location;
    data.currentLocation = {
      type: "Point",
      coordinates: [location.split(",")[0], location.split(",")[1]],
      address: wizardForm.address.value,
      description: wizardForm.description.value,
      postalCode: wizardForm.postalCode.value,
      province: wizardForm.province.value,
      title: wizardForm.title.value,
    };
  }
  return data;
};

export const confirmBtn = () => {
  document.getElementById("termsCheck").checked = true;
};
export const validate = async (data) => {
  try {
    if (!data.name) throw "Please fill the Full Name field in Profile Form.";
    if (
      !data.email ||
      !data.password ||
      !data.passwordConfirm ||
      data.password !== data.passwordConfirm
    )
      throw "Please fill the Email and Passwords in Account Form.";
    if (
      !data.currentLocation.address ||
      !data.currentLocation.description ||
      !data.currentLocation.province ||
      !data.currentLocation.postalCode ||
      !data.currentLocation.title
    )
      throw "Please fill the location information in Address Form.";

    const res = await fetch.patch("users/completeMe", data);
    if (res.data.status === "success") {
      showAlert("success", "Activation link was send to your email.", {
        msgType: "block",
        position: "center",
      });
      window.setTimeout(() => {
        location.assign(`/mailconfirmation/${data.email}`);
      }, 3000);
    }
  } catch (err) {
    const error = err.response ? err.response.data.message : err;
    showAlert("danger", error, {
      msgType: "block",
      position: "center",
    });
  }
};
