/* eslint-disable */
import "@babel/polyfill";
import * as loginView from "./login";
import IMask from "imask";
import { showAlert } from "./utils/alert";
import { countdown } from "./utils/timer";
import { elements } from "./base";
import { getMobile, mobileCode, testMobile } from "./utils/mobile";
import Modal from "./utils/Modal";
import Element from "./utils/Element";
import { showMap } from "./utils/mapbox";
import { nextValidate, confirmBtn, validate } from "./dashboard/completeWizard";
import { changePhoto, changePassword } from "./dashboard/profile";
import { suggestionWizard } from "./utils/wizard";
import {
  contractorSuggestion,
  acceptSuggestion,
  showPaymentConditions,
  acceptPaymentRequest,
} from "./dashboard/suggestionService";
import { requestService } from "./dashboard/requestService";
import { bookService, accountingRecord } from "./utils/stripe";
import { timeDiffCalc } from "./utils/timer";
import { invoiceSubmit } from "./dashboard/invoice";
import {
  locationChangeAction,
  locationUpdate,
  showLocation,
} from "./dashboard/locations";
import { submitRating } from "./dashboard/pendingBooking";
import { creditcard } from "./utils/card";
import {
  loadPartialPayment,
  deletePartialPayment,
  subtotalCalc,
  submitSuggestion,
  deleteSuggestRequest,
  acceptRequest,
  sendBill,
  clearInvoiceItems,
  addInvoiceItem,
  clearInvoiceOneItem,
  submitContractorInvoice,
} from "./dashboard/suggestRequest";
import {
  updateCardInfo,
  cardChangeAction,
  showCard,
} from "./dashboard/cardInformation";

import { notify } from "./utils/pushNotification";
// import { getAllServices } from "./searchServices";

// DOM ELEMENTS

// Home
if (elements.searchServicesForm) {
  const searchServicesForm = document.getElementById("searchServices");
  searchServicesForm.addEventListener("submit", async (e) => {
    e.preventDefault();
    const name = document.getElementById("searchServiceName").value;
    //process
    const oldBtn = document.getElementById("search-btn-submit");
    const el = new Element();
    const newBtn = el.createSpinnerBtn("", "none", {
      type: "button",
      color: "primary",
      class: "float-right",
    });
    oldBtn.insertAdjacentElement("afterend", newBtn);

    oldBtn.classList.add("d-none");
    await new Promise(
      window.setTimeout(async () => {
        newBtn.classList.add("d-none");
        oldBtn.classList.remove("d-none");
        location.assign(`/service-search?servicename=${name}`);
      }, 1500)
    );
  });
}

// VALUES

const maskOptions = {
  mask: "+{1}(000)000-00-00",
};
/**
 * Global State for app
 * - Search Object
 * - Login Object
 * -
 */
const state = {};
state.login = {};
state.wizard = {};
state.profile = { isBioChange: false };
state.contractor = {};
// DELEGATION

// NOTE:
// CONTROLLERS
// Login Control
const controlLogin = async () => {
  if (elements.signinCheckbox && elements.signinCheckbox.checked) {
    const email = elements.email.value;
    const password = elements.password.value;
    state.login.email = email;
    //state.login.password = password;
    await loginView.loginEmail(state.login.email, password);
  } else {
    if (elements.mobile) {
      if (testMobile(elements.mobile.value)) {
        state.login.mobile = getMobile(elements.mobile.value);
        const modal = new Modal(elements.modalConfirm);
        const content = `Please confirm your mobile number. The code will be send to ${elements.mobile.value}.`;
        modal.clearContent();
        modal.showContent("afterbegin", content);
      } else {
        showAlert("danger", "Please fill the phone number.");
        document.querySelector(".btn-block").dataset.target = "";
      }
    }
    if (elements.otp) {
      state.login.mobileCode = elements.otp.value;
      if (state.login.action === "login") {
        await loginView.loginMobile(state.login.mobile, state.login.mobileCode);
      } else if (state.login.action === "signup") {
        await loginView.signupMobile(
          state.login.mobile,
          state.login.mobileCode
        );
      }
    }
  }
};

// Resend Code Control
const resendCodeControl = async () => {
  try {
    await mobileCode(state.login.mobile, state.login.action);
    const otp =
      state.login.action === "login" ? elements.loginForm : elements.signupForm;
    countdown(2, elements.countdown, {
      findElement: otp.querySelector("#otp-code"),
      insertedElement: elements.resendCode,
    });
    const beforeResendElement = document.querySelector("#resendCode");
    if (beforeResendElement)
      beforeResendElement.parentElement.removeChild(beforeResendElement);
  } catch (err) {
    //FIXME: for catch error
    console.log(err);
  }
};

// Mobile Input Control
const mobileInputControl = () => {
  elements.signinMobile = document.getElementById("signin-mobile");
  elements.signupMobile = document.getElementById("signup-mobile");
  if (elements.signinMobile || elements.signupMobile) {
    elements.mobile = document.querySelector("input[name='mobile']");
    const mask = IMask(elements.mobile, maskOptions);
    elements.mobile.value = mask.value;
    mask.off("complete");
  }
};

// Modal Control
const controlConfirm = async (action) => {
  try {
    const result = await mobileCode(state.login.mobile, action);
    if (result) {
      const el = new Element();
      const resendCode = el.createResendCode();
      const otpForm = el.createOTP();
      if (elements.signinMethod) elements.signinMethod.classList.add("d-none");
      if (action === "login") {
        elements.signinMobile.parentElement.removeChild(elements.signinMobile);
        elements.loginForm.insertAdjacentElement("afterbegin", otpForm);
      } else if (action === "signup") {
        elements.signupMobile.parentElement.removeChild(elements.signupMobile);
        elements.signupForm.insertAdjacentElement("afterbegin", otpForm);
      }
      elements.mobile = null;
      elements.otp = document.querySelector("input[name='otp']");
      elements.resendCode = resendCode;
      countdown(2, elements.countdown, {
        findElement: otpForm,
        insertedElement: resendCode,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Signin Method Control
const controlSigninMethod = () => {
  const forgetPasswordItem = document.getElementById("forget-password");
  const el = new Element();
  el.clearForm(elements.loginForm);
  if (forgetPasswordItem)
    forgetPasswordItem.parentElement.removeChild(forgetPasswordItem);
  if (elements.signinCheckbox.checked) {
    elements.loginForm.insertAdjacentElement(
      "afterbegin",
      el.createSigninTraditional()
    );
    elements.details.insertAdjacentElement(
      "afterbegin",
      el.createForgetPassword()
    );
    elements.email = document.getElementById("emailaddress");
    elements.password = document.getElementById("password");
    elements.signinMobile = null;
  } else {
    elements.loginForm.insertAdjacentElement(
      "afterbegin",
      el.createSigninMobile()
    );
    elements.signinMobile = document.getElementById("signin-mobile");
  }
};
// Reset Password Control
//FIXME:
const resetPasswordControl = async () => {
  let newBtn;
  const el = new Element();
  const url = window.location.href.split("/");
  const token = url[url.length - 1];
  elements.resetPasswordBtn.classList.add("d-none");
  if (elements.forgetPasswordForm) {
    state.login.email = elements.email.value;
    newBtn = el.createSpinnerBtn("Sending...", "btn-block", {
      type: "button",
      color: "primary",
      class: "float-left",
    });
    elements.resetPasswordBtn.insertAdjacentElement("afterend", newBtn);
    await loginView.forgetPassword(state.login.email);
  } else if (elements.resetPasswordForm) {
    newBtn = el.createSpinnerBtn("Resetting...", "btn-block", {
      type: "button",
      color: "primary",
      class: "float-left",
    });
    elements.resetPasswordBtn.insertAdjacentElement("afterend", newBtn);
    await loginView.resetPassword(
      elements.password.value,
      elements.confirmPassword.value,
      token
    );
  }
  newBtn.classList.add("d-none");
  elements.resetPasswordBtn.classList.remove("d-none");
};

// NOTE:
// EVENT LISTENER
// Signin Method Change
if (elements.signinMethod) {
  elements.signinMethod.addEventListener("change", () => {
    controlSigninMethod();
  });
}
// Login Form Submit and Click
if (elements.loginForm) {
  elements.loginForm.addEventListener("submit", (e) => {
    e.preventDefault();
    state.login.action = "login";
    controlLogin();
  });
  elements.loginForm.addEventListener("click", (e) => {
    if (elements.resendCode && e.target === elements.resendCode.children[0]) {
      resendCodeControl();
    } else {
      mobileInputControl();
    }
  });
}
// Modal Confirm
if (elements.modalConfirm) {
  elements.modalConfirm.addEventListener("click", (e) => {
    if (e.target === elements.modalConfirmBtn) {
      controlConfirm(state.login.action, state.login.action);
    }
  });
}
// Logout
if (elements.logout) {
  elements.logout.addEventListener("click", loginView.logout);
}
// Signup Form Submit and Click
if (elements.signupForm) {
  elements.signupForm.addEventListener("submit", (e) => {
    e.preventDefault();
    state.login.action = "signup";
    controlLogin();
  });
  elements.signupForm.addEventListener("click", (e) => {
    if (elements.resendCode && e.target === elements.resendCode.children[0]) {
      resendCodeControl();
    } else {
      mobileInputControl();
    }
  });
}
if (elements.resetPasswordForm) {
  elements.resetPasswordForm.addEventListener("submit", (e) => {
    e.preventDefault();
    resetPasswordControl();
  });
}
if (elements.forgetPasswordForm) {
  elements.forgetPasswordForm.addEventListener("submit", (e) => {
    e.preventDefault();
    resetPasswordControl();
  });
}
//Complete Profile for start
if (elements.wizardForm) {
  elements.wizardForm.addEventListener("change", (e) => { });
  elements.wizardForm.addEventListener("click", async (e) => {
    if (e.target === document.getElementById("nextValidate")) {
      const step = document.querySelectorAll(".tab-pane.active")[0].id;
      state.wizard = { step };
      state.data = nextValidate(step);
      if (step === "fourth") {
        const el = new Element();
        const nextElem = document.querySelector(".next");
        const nextLink = document.getElementById("nextValidate");
        nextLink.classList.add("d-none");
        const newBtn = el.createSpinnerBtn("Sending ...", "none", {
          type: "a",
          color: "secondary",
          class: "float-right",
        });
        nextElem.insertAdjacentElement("beforeend", newBtn);
        await validate(state.data);
        newBtn.classList.add("d-none");
        nextLink.classList.remove("d-none");
      }
    }
  });
}
if (elements.termsAndConditions) {
  elements.termsAndConditions.addEventListener("click", (e) => {
    if (e.target === elements.modalConfirmBtn) confirmBtn();
  });
}

if (elements.profilePhotoForm) {
  elements.profilePhotoForm.addEventListener("submit", async (e) => {
    e.preventDefault();
    if (
      document.getElementById("upload-photo").files[0] ||
      state.profile.isBioChange
    ) {
      const el = new Element();
      const oldBtn = document.getElementById("profile-photo-save");
      oldBtn.classList.add("d-none");
      const newBtn = el.createSpinnerBtn("Sending ...", "none", {
        type: "button",
        color: "pink",
        class: "float-right",
      });
      oldBtn.insertAdjacentElement("afterend", newBtn);
      await changePhoto();
      newBtn.classList.add("d-none");
      oldBtn.classList.remove("d-none");
    }
  });
  elements.profilePhotoForm.addEventListener("click", (e) => {
    if (e.target === document.getElementById("edit-icon-bio")) {
      document.getElementById("bio-form").classList.remove("d-none");
      document.getElementById("bio").classList.add("d-none");
      state.profile = { isBioChange: true };
    }
    if (e.target === document.getElementById("edit-icon-bdate")) {
      document.getElementById("bdate-form").classList.remove("d-none");
      document.getElementById("bdate").classList.add("d-none");
      state.profile = { isBioChange: true };
    }
    if (e.target === document.getElementById("edit-icon-phone")) {
      document.getElementById("phone-form").classList.remove("d-none");
      document.getElementById("phone").classList.add("d-none");
      state.profile = { isBioChange: true };
    }
  });
}

if (elements.profilePasswordForm) {
  document.getElementById("oldPassword").addEventListener("focus", () => {
    if (!elements.profilePasswordForm.classList.contains("was-validated")) {
      elements.profilePasswordForm.classList.add("was-validated");
    }
  });
  elements.profilePasswordForm.addEventListener("submit", async (e) => {
    e.preventDefault();
    const el = new Element();
    const oldBtn = document.getElementById("update-password-save");
    oldBtn.classList.add("d-none");
    const newBtn = el.createSpinnerBtn("Sending ...", "none", {
      type: "button",
      color: "pink",
      class: "float-right",
    });
    oldBtn.insertAdjacentElement("afterend", newBtn);
    await changePassword();
    newBtn.classList.add("d-none");
    oldBtn.classList.remove("d-none");
  });
}

//NOTE: Suggestions Form
if (elements.suggestionForm) {
  const action = document.getElementById("suggestions").dataset.action;
  if (action === "selectWinner") {
    const contractors = document.getElementById("contractors");
    const ContractorSelector = document.getElementById("ContractorSelector");
    if (contractors) {
      document
        .getElementById("contractors")
        .addEventListener("click", async (e) => {
          e.preventDefault();
          const listItem = e.target.closest("#contractors > li");
          const contractorId = listItem.dataset.contractorId;
          state.contractor = { id: contractorId };
          const el = new Element();
          const pElement = document.getElementById("contractorLable");
          const oldText = el.createSpinnerPragraph(pElement, "Processing", {
            position: "beforeend",
          });
          await contractorSuggestion(contractorId, true);
          document.getElementById("contractorLable").textContent = oldText;
        });
    }

    if (ContractorSelector) {
      document
        .getElementById("ContractorSelector")
        .addEventListener("change", async (e) => {
          const contractor = document.getElementById("ContractorSelector");
          if (e.target.value !== "Select Contractor") {
            state.contractor = { id: contractor.value };
            // process
            const el = new Element();
            const pElement = document.getElementById("contractorLable");
            const oldText = el.createSpinnerPragraph(pElement, "Processing", {
              position: "beforeend",
            });
            await contractorSuggestion(contractor.value, false);
            document.getElementById("contractorLable").textContent = oldText;
          }
        });
    }
  } else if (action === "payment") {
    const data = {
      currentstep: 1,
      steps: [
        {
          id: 1,
          title: "Contractor",
          icon_class: "mdi mdi-account-check-outline",
        },
        {
          id: 2,
          title: "Billing",
          icon_class: "mdi mdi-note-outline",
        },
        {
          id: 3,
          title: "Payment",
          icon_class: "mdi mdi-credit-card",
        },
      ],
    };
    suggestionWizard(data);
    const contractor =
      document.getElementById("suggestions").dataset.contractor;
    state.contractor = { id: contractor };
    contractorSuggestion(contractor, false);
  } else if (action === "paymentRequest") {
    const contractor =
      document.getElementById("suggestions").dataset.contractor;
    state.contractor = { id: contractor };
    showPaymentConditions();
  }
}

//
if (document.getElementById("suggestions")) {
  document
    .getElementById("suggestions")
    .addEventListener("submit", async (e) => {
      e.preventDefault();
      const bookingId = document.getElementById("booking-id").dataset.bookingId;
      const serviceName = document
        .getElementById("booking-id")
        .textContent.trim();
      const contractorId = state.contractor.id;
      const totalPriceString =
        document.getElementById("total-price").textContent;
      const price = parseFloat(totalPriceString.substr(2).replace(/(,)/g, ""));
      const action = document.getElementById("suggestions").dataset.action;

      if (action === "selectWinner") {
        // process
        const el = new Element();
        const oldBtn = document.getElementById("accept-suggestion");
        oldBtn.classList.add("d-none");
        const newBtn = el.createSpinnerBtn("Sending ...", "none", {
          type: "button",
          color: "pink",
          class: "float-right",
        });
        oldBtn.insertAdjacentElement("afterend", newBtn);
        await acceptSuggestion(bookingId, contractorId);
        newBtn.classList.add("d-none");
        oldBtn.classList.remove("d-none");
      }
      if (action === "payment") {
        const serviceFee = parseFloat(
          document
            .getElementById("serviceFee")
            .textContent.substr(2)
            .replace(/(,)/g, "")
        );
        // process
        const el = new Element();
        const pElement = document.getElementById("checkout-content");
        const oldText = el.createSpinnerPragraph(pElement, "Processing", {
          position: "beforeend",
        });
        await accountingRecord(price, "credit", `For ${serviceName} Service`);
        await bookService(bookingId, "cad", price, contractorId, serviceFee);
        document.getElementById("checkout-content").textContent = oldText;
      }
      if (action === "paymentRequest") {
        const paymentId =
          document.getElementById("payment-request-id").dataset.paymentId;
        //process
        const el = new Element();
        const oldBtn = document.getElementById("accept-request");
        oldBtn.classList.add("d-none");
        const newBtn = el.createSpinnerBtn("Sending ...", "none", {
          type: "button",
          color: "pink",
          class: "float-right",
        });
        oldBtn.insertAdjacentElement("afterend", newBtn);
        await acceptPaymentRequest(bookingId, contractorId, paymentId);
        newBtn.classList.add("d-none");
        oldBtn.classList.remove("d-none");
      }
    });
}

if (elements.requestServiceForm) {
  const data = {
    currentstep: 1,
    steps: [
      {
        id: 1,
        title: "Service",
        icon_class: "mdi mdi-hail",
      },
      {
        id: 2,
        title: "Details",
        icon_class: "mdi mdi-format-list-checks",
      },
      {
        id: 3,
        title: "Service Location",
        icon_class: "mdi mdi-home-map-marker",
      },
      {
        id: 4,
        title: "Upload Image",
        icon_class: "mdi mdi-camera-wireless",
      },
      {
        id: 5,
        title: "Submit",
        icon_class: "mdi mdi-marker-check",
      },
    ],
  };
  suggestionWizard(data);
  document
    .getElementById("requestServiceForm")
    .addEventListener("submit", async (e) => {
      e.preventDefault();
      // process
      const el = new Element();
      const pElement = document.getElementById("checkout-content");
      const oldText = el.createSpinnerPragraph(pElement, "Processing", {
        position: "beforeend",
      });
      await requestService();
      document.getElementById("checkout-content").textContent = oldText;
    });
}

//Submit The Invoice
if (elements.invoiceSubmit) {
  document
    .getElementById("invoice-form")
    .addEventListener("submit", async (e) => {
      e.preventDefault();
      if (
        !document
          .getElementById("invoice-submit")
          .classList.contains("disabled")
      ) {
        //processs
        const el = new Element();
        const oldBtn = document.getElementById("invoice-submit");
        oldBtn.classList.add("d-none");
        const newBtn = el.createSpinnerBtn("submiting ...", "none", {
          type: "button",
          color: "primary",
        });
        oldBtn.insertAdjacentElement("afterend", newBtn);
        await invoiceSubmit();
        newBtn.classList.add("d-none");
        oldBtn.classList.remove("d-none");
      }
    });
}
//NOTE: Locations data Select
if (elements.locationsData) {
  document
    .getElementById("locations-data")
    .addEventListener("click", async (e) => {
      //For Load Location
      await showLocation(e.target);
      //FOR Location Change
      await locationChangeAction(e.target);
    });
}
if (elements.locationForm) {
  document
    .getElementById("locationForm")
    .addEventListener("submit", async (e) => {
      e.preventDefault();
      const locationData = document.getElementById("map").dataset.location;
      const userId = document.getElementById("user-info").dataset.id;
      const location = {
        type: "Point",
        coordinates: [
          parseFloat(locationData.split(",")[0]),
          parseFloat(locationData.split(",")[1]),
        ],
        description: document.getElementById("description").value,
        address: document.getElementById("address").value,
        province: document.getElementById("province").value,
        postalCode: document.getElementById("postalcode").value,
        title: document.getElementById("title").value,
      };
      //Process
      const el = new Element();
      const oldBtn = document.getElementById("new-location-save");
      oldBtn.classList.add("d-none");
      const newBtn = el.createSpinnerBtn("Adding Location ...", "none", {
        type: "button",
        color: "pink",
        class: "float-right",
      });
      oldBtn.insertAdjacentElement("afterend", newBtn);
      await locationUpdate(userId, location, "add", "#response-title");
      newBtn.classList.add("d-none");
      oldBtn.classList.remove("d-none");
      //
    });
}

//NOTE: Rating
if (elements.submitRatingForm) {
  document
    .getElementById("submit-rating-form")
    .addEventListener("submit", async (e) => {
      e.preventDefault();
      const bookingId = document.getElementById("booking-id").dataset.bookingId;
      const rating = document.getElementById("submit-rating").dataset.rating;
      //process
      const el = new Element();
      const oldBtn = document.getElementById("submitting");
      oldBtn.classList.add("d-none");
      const newBtn = el.createSpinnerBtn("Submitting ...", "none", {
        type: "button",
        color: "pink",
        class: "float-right",
      });
      oldBtn.insertAdjacentElement("afterend", newBtn);
      await submitRating(bookingId, rating);
      newBtn.classList.add("d-none");
      oldBtn.classList.remove("d-none");
    });
}

//NOTE: Contractor Suggest to the Request
if (elements.suggestRequestForm) {
  const data = {
    currentstep: 1,
    steps: [
      {
        id: 1,
        title: "Service",
        icon_class: "mdi mdi-hail",
      },
      {
        id: 2,
        title: "Details",
        icon_class: "mdi mdi-format-list-checks",
      },
      {
        id: 3,
        title: "Submit",
        icon_class: "mdi mdi-marker-check",
      },
    ],
  };
  suggestionWizard(data);
  const partialPayment = {
    rowNumber: document.querySelectorAll(".partial-price").length,
  };
  document
    .getElementById("add-partial-payment")
    .addEventListener("click", () => {
      if (partialPayment.rowNumber) {
        partialPayment.rowNumber++;
      } else {
        partialPayment.rowNumber = 2;
      }
      loadPartialPayment(partialPayment);
    });
  document.getElementById("payment-details").addEventListener("click", (e) => {
    const deleteLink = e.target.closest("a.delete-payment");
    if (deleteLink) {
      partialPayment.rowNumber = deletePartialPayment(deleteLink);
    }
  });
  document.getElementById("isPartialPayment").addEventListener("click", (e) => {
    if (e.target === document.querySelector("#isPartialPayment>input")) {
      const isPartial = document.querySelector(
        "#isPartialPayment>input"
      ).checked;
      if (isPartial) {
        document.getElementById("payment-details").style["pointer-events"] =
          null;
        document.querySelector(".partial-price").setAttribute("required", "");
        document.querySelector(".partial-name").setAttribute("required", "");
        document.getElementById("total-price").readOnly = true;
      } else {
        document.getElementById("payment-details").style["pointer-events"] =
          "none";
        document.getElementById("total-price").readOnly = false;
        document.querySelector(".partial-price").removeAttribute("required");
        document.querySelector(".partial-name").removeAttribute("required");
      }
    }
  });
  document.getElementById("payment-details").addEventListener("keyup", (e) => {
    const partialPrice = e.target.closest("input.partial-price");
    subtotalCalc(partialPrice);
  });
  document
    .getElementById("suggestRequestForm")
    .addEventListener("submit", async (e) => {
      e.preventDefault();
      //process
      const el = new Element();
      const pElement = document.getElementById("submit-content");
      const oldText = el.createSpinnerPragraph(pElement, "Submitting", {
        position: "beforeend",
      });
      await submitSuggestion();
      document.getElementById("submit-content").textContent = oldText;
    });
}

//NOTE: Select Request
if (document.getElementById("select-request")) {
  document
    .getElementById("accept-delete")
    .addEventListener("click", async (e) => {
      if (e.target === document.getElementById("delete-confirm")) {
        const bookingId = document.getElementById("accept-delete").dataset.id;
        //process
        const el = new Element();
        const oldBtn = document.getElementById("delete-confirm");
        oldBtn.classList.add("d-none");
        const newBtn = el.createSpinnerBtn("Deleting ...", "none", {
          type: "button",
          color: "danger",
          class: "float-right",
        });
        oldBtn.insertAdjacentElement("afterend", newBtn);
        await deleteSuggestRequest(bookingId);
        newBtn.classList.add("d-none");
        oldBtn.classList.remove("d-none");
        $("#accept-delete").modal("hide");
      }
    });
  document.getElementById("select-request").addEventListener("click", (e) => {
    const deleteLink = e.target.closest("a.text-danger");
    if (deleteLink)
      document.getElementById("accept-delete").dataset.id =
        deleteLink.dataset.id;
  });
}

//NOTE: Accepting Request
if (elements.acceptSuggestionForm) {
  document
    .getElementById("accept-suggestion-form")
    .addEventListener("click", async (e) => {
      e.preventDefault();
      const sendData = {
        bookingId: document.getElementById("booking-id").dataset.bookingId,
      };
      if (e.target === document.getElementById("accept-request")) {
        sendData.btn = "pink";
        sendData.btnId = "accept-request";
        sendData.align = "float-right";
        sendData.message = "Accepting ...";
        sendData.action = "accepted";
      }
      if (e.target === document.getElementById("reject-request")) {
        sendData.btn = "danger";
        sendData.btnId = "reject-request";
        sendData.align = "float-left";
        sendData.message = "Rejecting ...";
        sendData.action = "rejected";
      }
      if (sendData.action !== undefined) {
        //  process
        const el = new Element();
        const oldBtn = document.getElementById(`${sendData.btnId}`);
        oldBtn.classList.add("d-none");
        const newBtn = el.createSpinnerBtn(`${sendData.message}`, "none", {
          type: "button",
          color: sendData.btn,
          class: sendData.align,
        });
        oldBtn.insertAdjacentElement("afterend", newBtn);
        await acceptRequest(sendData.bookingId, sendData.action);
        newBtn.classList.add("d-none");
        oldBtn.classList.remove("d-none");
      }
    });
}

//NOTE: Send Payment Request by CONTRACTOR
if (elements.contractorRequestPaymentForm) {
  document
    .getElementById("contractor-request-payment-form")
    .addEventListener("submit", async (e) => {
      e.preventDefault();
      //process
      const el = new Element();
      const oldBtn = document.getElementById("contractor-send-request-payment");
      oldBtn.classList.add("d-none");
      const newBtn = el.createSpinnerBtn("Sending ...", "none", {
        type: "button",
        color: "pink",
        class: "float-right",
      });
      oldBtn.insertAdjacentElement("afterend", newBtn);
      await sendBill();
      newBtn.classList.add("d-none");
      oldBtn.classList.remove("d-none");
    });
}
if (elements.contractorInvoice) {
  const invoiceForm = document.getElementById("invoice-form");
  const contractorInvoice = document.getElementById("contractor-invoice");
  if (contractorInvoice) {
    contractorInvoice.addEventListener("click", (e) => {
      if (
        document.querySelectorAll(".delete-row").length > 0 &&
        e.target.closest(".delete-row")
      ) {
        e.preventDefault();

        clearInvoiceOneItem(e.target);
      }
    });
  }
  if (invoiceForm) {
    document
      .getElementById("invoice-form")
      .addEventListener("click", async (e) => {
        e.preventDefault();
        if (e.target === document.getElementById("invoice-clear"))
          clearInvoiceItems();
        if (e.target === document.getElementById("invoice-submit")) {
          e.preventDefault();
          //process
          const el = new Element();
          const oldBtn = document.getElementById("invoice-submit");
          oldBtn.classList.add("d-none");
          const newBtn = el.createSpinnerBtn("Submitting ...", "none", {
            type: "button",
            color: "primary",
            class: "float-right",
          });
          oldBtn.insertAdjacentElement("afterend", newBtn);
          await submitContractorInvoice();
          newBtn.classList.add("d-none");
          oldBtn.classList.remove("d-none");
        }
      });
    const manualForm = document.getElementById("manual-form");
    if (manualForm) {
      document.getElementById("manual-form").addEventListener("click", (e) => {
        if (
          e.target.closest("div#add-invoice-item-btn") ===
          document.getElementById("add-invoice-item-btn")
        ) {
          const title = document.getElementById("description").value;
          const quantity = document.getElementById("quantity").value;
          const price = document.getElementById("price").value;
          if (title && quantity && price)
            addInvoiceItem(title, price, quantity);
        }
      });
    }
  }
}

//NOTE: Credit Card
if (elements.submitCreditCardForm) {
  document
    .getElementById("credit-card-submitting")
    .addEventListener("submit", async (e) => {
      e.preventDefault();
      //process
      const el = new Element();
      const oldBtn = document.getElementById("submit-card-btn");
      oldBtn.classList.add("d-none");
      const newBtn = el.createSpinnerBtn("Submitting ...", "none", {
        type: "button",
        color: "pink",
        class: "float-right",
      });
      oldBtn.insertAdjacentElement("afterend", newBtn);
      await updateCardInfo();
      newBtn.classList.add("d-none");
      oldBtn.classList.remove("d-none");
    });
}

if (elements.cardsData) {
  document.getElementById("cards-data").addEventListener("click", async (e) => {
    //FOR Card Show
    showCard(e.target);
    //FOR Card Change
    await cardChangeAction(e.target);
  });
}

// if (searchServices) {
//   searchServices.addEventListener("submit", async (e) => {
//     e.preventDefault();
//     const name = document.querySelector("input[name='searchServices']").value;
//     getAllServices(name);
//   });
// }

//NOTE: COPYRIGHT
if (elements.copyright) {
  const el = new Element();
  el.createCopyright();
}
//NOTE: MAPBOX
if (elements.mapbox) {
  showMap();
}
// CHECK BOX to Show MAPBOX OR NOT
if (elements.showMapCheckBox) {
  const checkBox = document
    .getElementById("showMapCheckBox")
    .querySelector("input[type='checkbox']");
  const oldLocation = {
    address: document.getElementById("address").value,
    description: document.getElementById("description").value,
    province: document.getElementById("province").value,
    postalCode: document.getElementById("postalcode").value,
  };
  checkBox.addEventListener("change", (e) => {
    const checkBoxValue = checkBox.checked;
    if (checkBoxValue) {
      document.getElementById("map").classList.remove("d-none");
      document.getElementById("address").value = "";
      document.getElementById("description").value = "";
      document.getElementById("province").value = "";
      document.getElementById("postalcode").value = "";
    } else {
      document.getElementById("address").value = oldLocation.address;
      document.getElementById("description").value = oldLocation.description;
      document.getElementById("province").value = oldLocation.province;
      document.getElementById("postalcode").value = oldLocation.postalCode;
      document.getElementById("map").classList.add("d-none");
    }
  });
}

//NOTE: Time Different Calculation
if (elements.diffTime) {
  const times = document.querySelectorAll(".diff-time");
  for (var time of times) {
    time.textContent = timeDiffCalc(time.textContent);
  }
}

//NOTE: Submit Rating
if (elements.submitRating) {
  document.getElementById("submit-rating").addEventListener("click", (e) => {
    if (document.getElementById("submit-rating").classList.contains("disabled"))
      return;
    const totalStar = parseInt(
      document.getElementById("submit-rating").dataset.rating
    );
    const star = parseInt(e.target.closest("svg").dataset.star);
    if (totalStar <= star) {
      for (var i = 0; i < star; i++) {
        document
          .querySelector(`[data-star='${i + 1}']`)
          .classList.remove("reviews__star--inactive");
        document
          .querySelector(`[data-star='${i + 1}']`)
          .classList.add("reviews__star--active");
      }
    } else {
      for (var i = totalStar - 1; i >= star; i--) {
        document
          .querySelector(`[data-star='${i + 1}']`)
          .classList.remove("reviews__star--active");
        document
          .querySelector(`[data-star='${i + 1}']`)
          .classList.add("reviews__star--inactive");
      }
    }
    document.getElementById("submit-rating").dataset.rating = star;
    document.getElementById("rating-value").textContent = `(${star})`;
  });
}

// Credit Card Show
if (document.querySelector("#credit-card-submitting")) {
  creditcard();
}

//NOTE: Notification
if (document.getElementById("notificationShow")) {
  document.addEventListener("DOMContentLoaded", async () => {
    try {


      // Handler when the DOM is fully loaded
      const userId = document.getElementById("userRole").dataset.id;
      const pushNotifyChannel = `${userId}-channel`;
      // await notify(pushNotifyChannel, "booking-change");
      const result = await notify(pushNotifyChannel, "booking-change");
    } catch (error) {
      console.log(error);
    }
  });
}
