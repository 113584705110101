import axios from "axios";

export const BASE_API_URL = `http://localhost:8804/api/v1/`;
export const COMPANY_NAME = "OutWorks";

export const DOMAIN = "https://outworks.ir";
// export const DOMAIN = "http://localhost:6009";
// export const DOMAIN = "https://avamafarin-nodejs-app.liara.run";
export const NOTIFY_KEY = "ce8956b4b93c307341fc";
export const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});
