import axios from "axios";
import { showAlert } from "./alert";
import { BASE_API_URL, DOMAIN } from "../config";
import AppError from "../../../utils/appError";

const stripe = Stripe(
  "pk_test_51H02jmEK8634DYpDEppo5eNgzSZgSk3zbO5vIJXeOupzhd7OIVClQYMOJFRyJyR2sQYWvngQg6AJ7DcXK6I8Vgin00I4fOPvy1"
);
/** Define base API url */
const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});
/**
 * Stripe Function for sending booking
 * @param {String} bookingId The Booking Id
 * @param {String} currency The currency of price
 * @param {Number} price The total price with decimal
 * @param {String} contractorId The winner contractor id
 * @param {Number} serviceFee The price of our service
 */
export const bookService = async (
  bookingId,
  currency,
  price,
  contractorId,
  serviceFee
) => {
  try {
    //TODO:
    // 1) Get checkout session from API
    if (!contractorId)
      return new AppError("There is no contractor id", 404, {});
    const session = await fetch.post(`bookings/checkout-session/${bookingId}`, {
      currency,
      price,
      contractorId,
      serviceFee,
    });

    // 2) Create Checkout Form and charge credit card

    await stripe.redirectToCheckout({
      sessionId: session.data.session.id,
    });
  } catch (err) {
    showAlert("danger", err.message, {
      msgType: "block",
      position: "center",
    });
    window.setTimeout(() => {
      location.assign(`/service-suggestions`);
    }, 3000);
  }
};

/**
 * Create "credit" or "debit" in Accounting Model
 * @param {Number} amount The Value of charge
 * @param {("credit"|"debit")} accType The type of accounting record
 * @param {String} description The description of accounting record.
 */
export const accountingRecord = async (amount, accType, description) => {
  // try {
  //   await fetch.post("accountings", {
  //     payment: {
  //       amount,
  //     },
  //     accType,
  //     description,
  //     transaction_sequence: Date.now(),
  //   });
  // } catch (error) {
  //   showAlert("danger", error.data.message, {
  //     msgType: "text",
  //     position: "center",
  //   });
  // }
};
