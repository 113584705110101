export const elements = {
  //NOTE: FORMS
  searchServicesForm: document.getElementById("searchServices"),
  loginForm: document.getElementById("login"),
  signupForm: document.getElementById("signup"),
  resetPasswordForm: document.getElementById("resetPassword"),
  forgetPasswordForm: document.getElementById("forgetPassword"),
  modalConfirm: document.getElementById("modalConfirm"),
  termsAndConditions: document.getElementById("termsAndConditions"),
  wizardForm: document.getElementById("rootwizard"),
  profilePhotoForm: document.getElementById("profile-photo-data-form"),
  profilePasswordForm: document.getElementById("update-password"),
  suggestionForm: document.getElementById("suggestions"),
  requestServiceForm: document.getElementById("requestServiceForm"),
  suggestRequestForm: document.getElementById("suggestRequestForm"),
  locationForm: document.getElementById("locationForm"),
  submitRatingForm: document.getElementById("submit-rating-form"),
  submitCreditCardForm: document.getElementById("credit-card-submitting"),
  acceptSuggestionForm: document.getElementById("accept-suggestion-form"),
  contractorRequestPaymentForm: document.getElementById(
    "contractor-request-payment-form"
  ),

  //NOTE: FORM PARENT
  signinMethod: document.getElementById("signin-method"),
  signinMobile: document.getElementById("signin-mobile"),
  signinTraditional: document.getElementById("signin-traditional"),
  signupMobile: document.getElementById("signup-mobile"),

  //NOTE: INPUT TEXT, CHECKBOX, EMAIL, ...
  signinCheckbox: document.getElementById("checkbox-signin-email"),
  mobile: document.querySelector("input[name='mobile']"),
  otp: document.querySelector("input[name='otp']"),
  email: document.getElementById("emailaddress"),
  password: document.getElementById("password"),
  confirmPassword: document.getElementById("confirm-password"),
  showMapCheckBox: document.getElementById("showMapCheckBox"),

  //NOTE: BUTTONS
  modalConfirmBtn: document.getElementById("confirm-btn"),
  resetPasswordBtn: document.getElementById("resetPasswordBtn"),
  profilePhotoSave: document.getElementById("profile-photo-save"),

  //NOTE: PARAGRAPHS
  countdown: document.getElementById("countdown"),
  details: document.getElementById("details"),

  //NOTE: LISTITEMS
  locationsData: document.getElementById("locations-data"),
  cardsData: document.getElementById("cards-data"),

  //NOTE: ANCHOR LINKS
  forgetPassword: document.getElementById("forget-password"),
  logout: document.getElementById("logout"),
  nextValidate: document.getElementById("nextValidate"),
  invoiceSubmit: document.getElementById("invoice-submit"),

  //NOTE: TABLES
  contractorInvoice: document.getElementById("contractor-invoice"),

  //NOTE: COPYRIGHT
  copyright: document.getElementById("copyright"),

  //NOTE: Time and Date
  diffTime: document.querySelector(".diff-time"),

  //NOTE: Map
  mapbox: document.getElementById("map"),

  //NOTE: SUBMIT Rating
  submitRating: document.getElementById("submit-rating"),
};
