/**
 * @param {Number} timer timer in minute less than One hour
 * @param {Element} element Element that write timer on it
 * @param {{findElement: String, insertedElement:Element}} extra The Object for extra features after timeout

 */

export const countdown = (timer, element, extra = {}) => {
  element.innerHTML = "";
  element.classList.remove("text-danger");
  element.classList.remove("small");
  // Set the date we're counting down to
  const countDownDate = new Date(Date.now() + timer * 60 * 1000).getTime();
  // Update the count down every 1 second
  const timerShow = setInterval(function () {
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    //var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    element.innerHTML = `${minutes < 10 ? 0 : ""}${minutes}:${
      seconds < 10 ? 0 : ""
    }${seconds}`;

    // If the count down is over, write some text
    if (distance < 0) {
      clearInterval(timerShow);
      element.innerHTML = "Your token has Eexpired. Please try again.";
      element.classList.add("text-danger");
      element.classList.add("small");
      if (extra) {
        const findElement = extra.findElement;
        const insertedElement = extra.insertedElement;
        findElement.insertAdjacentElement("afterend", insertedElement);
      }
    }
  }, 1000);
};
/**
 * Convert different of your date with Now
 * @param {Date} date The date in ISODate format
 */
export const timeDiffCalc = (date) => {
  const now = new Date();
  const diff = (now - new Date(date)) / (1000 * 3600);
  if (diff < 1 && diff * 60 < 1) {
    return `${Math.floor(diff * 60 * 60)} seconds ago`;
  }
  if (diff < 1 && diff * 60 > 1 && diff * 60 < 2) {
    return `1 minute ago`;
  }
  if (diff < 1 && diff * 60 > 1 && diff * 60 < 60) {
    return `${Math.floor(diff * 60)} minutes ago`;
  }
  if (diff >= 1 && diff < 2) {
    return `${Math.floor(diff)} hour ago`;
  }
  if (diff > 2 && diff < 24) {
    return `${Math.floor(diff)} hours ago`;
  }
  if (diff > 24) {
    return `${new Date(date).toISOString().split("T")[0]}`;
  }
};
