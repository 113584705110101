export const hideAlert = () => {
  const el = document.querySelector(".alert,.alert-text");
  if (el) el.parentElement.removeChild(el);
};
/**
 * @param {("danger" | "success" | "warning" | "info" | "light" | "dark" | "primary" | "secondary")} types Type of alert
 * @param {String} msg Write your message content.
 * @param {{msgType:("block"|"text"),position:("left"|"right"|"center")}} Options To define message type is block or text.
 */
export const showAlert = (
  types,
  msg,
  Options = {
    msgType: "block",
    position: "center",
    ...(true && { elementShow: "#response-title" }),
  }
) => {
  hideAlert();
  let markup;
  if (Options.msgType === "block") {
    markup = `<div class='alert alert-${types}'><p class='text-${Options.position} mb-0'>${msg}</p></div>`;
  } else if (Options.msgType === "text") {
    markup = `<p class='alert-text text-${types} text-${Options.position} small'>${msg}</p>`;
  }
  if (Options.elementShow && typeof Options.elementShow !== "string") {
    Options.elementShow.insertAdjacentHTML("afterend", markup);
  } else {
    document
      .querySelector(
        `${Options.elementShow ? Options.elementShow : "#response-title"}`
      )
      .insertAdjacentHTML("afterend", markup);
  }
  window.setTimeout(hideAlert, 5000);
};
