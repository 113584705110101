import axios from "axios";
import { BASE_API_URL, DOMAIN } from "../config";
import { showAlert } from "../utils/alert";

const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});

export const invoiceSubmit = async () => {
  const dataSend = {};
  const bookingId = document.getElementById("booking-id").dataset.bookingId;
  dataSend.bookingId = bookingId;
  const res = await fetch.get(`bookings/${bookingId}`);
  if (res.data.status === "success") {
    const booking = res.data.data.data;
    const winnerContractor = booking.winnerContractor.contractor;
    dataSend.contractorId = winnerContractor;
    let winnerPaymentCoditions = [];
    for (let contractor of booking.contractors) {
      if (`${contractor.contractor}` === `${winnerContractor}`) {
        const conditions = contractor.paymentConditions.length;
        winnerPaymentCoditions.push(
          contractor.paymentConditions[conditions - 1]
        );
        break;
      }
    }
    dataSend.paymentId = winnerPaymentCoditions[0]._id;
    const newRes = await fetch.patch(
      `bookings/${dataSend.bookingId}/contractor/${dataSend.contractorId}/paymentConditions/${dataSend.paymentId}`,
      {
        status: "accepted",
      }
    );
    if (newRes.data.status === "success") {
      showAlert("success", "The invoice is submitted.");
      window.setTimeout(() => {
        location.assign(`/service-process`);
      }, 3000);
    }
  }
};
