import axios from "axios";
import { BASE_API_URL, DOMAIN } from "../config";
import { showAlert } from "../utils/alert";

const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});

export const requestService = async () => {
  const form = new FormData();
  form.append("photos", document.getElementById("upload-photo").files[0]);
  form.append("title", document.getElementById("booking-title").value);
  form.append("summary", document.getElementById("booking-summary").value);
  const date = document.getElementById("datepicker-autoclose").value;
  const time = document.getElementById("timepicker2").value;
  const preferredDate =
    date.split("/").length === 3
      ? new Date(
          parseInt(date.split("/")[2]),
          parseInt(date.split("/")[0]) - 1,
          parseInt(date.split("/")[1]),
          parseInt(time.split(":")[0]),
          parseInt(time.split(":")[1]),
          0
        )
      : new Date();
  form.append("preferredDate", preferredDate);
  const serviceId = document.getElementById("service-id").dataset.serviceId;
  const checkBox = document
    .getElementById("showMapCheckBox")
    .querySelector("input[type='checkbox']");
  if (checkBox.checkBox) {
    const location = document.getElementById("map").dataset.location;
    const serviceLocation = {
      type: "Point",
      coordinates: [
        parseFloat(location.split(",")[0]),
        parseFloat(location.split(",")[1]),
      ],
      description: document.getElementById("description").value,
      address: document.getElementById("address").value,
      province: document.getElementById("province").value,
      postalCode: document.getElementById("postalcode").value,
      title: document.getElementById("title").value,
    };
    form.append("serviceLocation", JSON.stringify(serviceLocation));
  }
  await updateSettings(form, serviceId);
};

/**
 *
 * @param {FormData} data is an Object to update user information.
 * @param {String} serviceId The service Id that user selected.
 */
const updateSettings = async (data, serviceId) => {
  const url = `/services/${serviceId}/bookings`;
  const elementShow = document.getElementById("response-title");
  try {
    const res = await fetch.post(url, data);
    if (res.data.status === "success") {
      document.getElementById("checkout-content").textContent = "";
      showAlert("success", "Your request is created.", {
        msgType: "block",
        position: "center",
        elementShow,
      });
      window.setTimeout(() => {
        location.assign(`/service-process`);
      }, 3000);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message, {
      msgType: "text",
      position: "center",
      elementShow,
    });
  }
};
