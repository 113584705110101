class AppError extends Error {
  /**
   *
   * @param {string} message Error Message
   * @param {number} statusCode Error Code
   * @param {Object} errorObject Error Object
   */
  constructor(message, statusCode, errorObject = {}) {
    super(message);

    this.errorObject = errorObject;
    this.statusCode = statusCode;
    this.status = `${statusCode}`.startsWith("4") ? "failed" : "error";
    this.isOperational = true; // for does not raise at any error (Only for Operational Error)

    Error.captureStackTrace(this, this.constructor); // for show path of error occured
  }
}

module.exports = AppError;
