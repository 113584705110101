import axios from "axios";
import { showAlert } from "./alert";
import { BASE_API_URL, DOMAIN } from "../config";

/** Define base API url */
const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});
const pattren = /(\+\d{1,2}\(\d{3}\)\d{3}\-\d{2}\-\d{2})/g;

/**
 * Return mobile number without any mask that you pre defined.
 * @param {String} mobile The mobile number that has format shape
 */
export const getMobile = (mobile) => {
  const mask = mobile.match(pattren)[0];
  const unmasked = mask.replace(/(\+|\(|\)|-|\ )/g, "");
  return unmasked.substr(unmasked.length - 10, 10);
};
/**
 * Return true/false for checking the mobile number matches with mobile format.
 * @param {String} mobile The mobile number that you want check format.
 */
export const testMobile = (mobile) => {
  return pattren.test(mobile);
};
/**
 * Return the unique code for your mobile number consider the action.
 * @param {String} mobile The mobile number that you want to generate code.
 * @param {("login"|"signup")} action The action for create code.
 */
export const mobileCode = async (mobile, action) => {
  try {
    const res = await fetch.post("users/mobileCode", {
      mobile,
      action,
    });
    if (res.data.status === "success") {
      showAlert("success", "Confirmation code was send.");
      return true;
    }
  } catch (err) {
    showAlert("danger", err.response.data.message);
    return false;
  }
};
