import axios from "axios";
import { BASE_API_URL, DOMAIN } from "../config";
import { showAlert } from "../utils/alert";

const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? BASE_API_URL : `${DOMAIN}/api/v1/`,
});
/**
 * The Tax Value in province
 * @param {String} province The province in symbol or complete name that you want to calculate TAX
 */
const taxtValue = async (province) => {
  try {
    const res = await fetch.get(`taxes/province/${province}`);
    return res.data.data.data;
  } catch (error) {
    return { PST: 0, GST: 0 };
  }
};
/**
 * Return the html for filling payment conditions
 * @param {String} contractorId The contractor Id
 * @param {Boolean} hidden The condition for show or hide the Contractor Selection Option
 */
export const contractorSuggestion = async (contractorId, hidden = true) => {
  const province = document.getElementById("province").textContent;
  const tax = await taxtValue(province);
  const { PST, GST } = tax.taxValue;
  const currency = tax.currency;
  const element = document.getElementById(`${contractorId}`);
  const paymentData = JSON.parse(
    element.querySelector(".paymentConditions").dataset.conditions
  );
  const price = element.querySelector(".price").textContent;
  const subtotal = price * (1 + 0.07);
  const totalPrice =
    Math.round(((100 + PST + GST) / 100) * subtotal * 100) / 100;
  let paymentHtml = "<ul>";
  for (let item of paymentData) {
    paymentHtml += `
      <li>
        <div class='row'>
          <div class='col-6 col-md-6'>${item.name}</div>
          <div class='col-6 col-md-6'>$ ${currencyFormat(item.price)}</div>
        </div>
      </li>`;
  }
  const totalPaymentHtml = `
    <ul>
      <li>
        <div class='row'>
          <div class='col-6 col-md-6'>Price:</div>
          <div class='col-6 col-md-6'>$ ${currencyFormat(price)}</div>
        </div>
      </li>
      <li>
        <div class='row'>
          <div class='col-6 col-md-6'>Service Fee:</div>
          <div class='col-6 col-md-6' id='serviceFee'>$ ${currencyFormat(
            0.07 * price
          )}</div>
        </div>
      </li>
    </ul>
    <hr class='border border-2'>
    <ul>
      <li>
        <div class='row'>
          <div class='col-6 col-md-6'>PST (${PST} %):</div>
          <div class='col-6 col-md-6'>$ ${currencyFormat(
            (PST * subtotal) / 100
          )}</div>
        </div>
      </li>
      <li>
        <div class='row'>
          <div class='col-6 col-md-6'>GST (${GST} %):</div>
          <div class='col-6 col-md-6'>$ ${currencyFormat(
            (GST * subtotal) / 100
          )}</div>
        </div>
      </li>
    </ul>
    <hr class='border border-2'>
    <ul>
      <li>
        <div class='row'>
          <div class='col-6 col-md-6'>TOTAL in (${currency}):</div>
          <div class='col-6 col-md-6' id='total-price'>$ ${currencyFormat(
            totalPrice
          )}</div>
        </div>
      </li>
    </ul>
  `;

  document.getElementById("summary").textContent = element.querySelector(
    ".summary"
  ).textContent;
  document.getElementById("contractor-selected").textContent =
    "Contractor-" + element.querySelector(".contractor-selected").textContent;
  document.getElementById("duration").textContent = element.querySelector(
    ".duration"
  ).textContent;
  document.getElementById("price").textContent = "$ " + currencyFormat(price);
  document.getElementById("paymentConditions").innerHTML =
    paymentHtml + "</ul>";

  document.getElementById("totalPayment").innerHTML = totalPaymentHtml;
  const isFill = document.getElementById("suggestions").dataset.fill;

  if (hidden && !isFill) {
    document.getElementById("ContractorSelector").classList.toggle("d-none");
    document.getElementById("contractor-selected").classList.toggle("d-none");
    document.getElementById("suggestions").dataset.fill = true;
  }
};
/**
 * Convert Number to Currency Format
 * @param {Number} Number The number that you want to change in currency formet
 */
const currencyFormat = (Number) => {
  return (Math.round(Number * 100) / 100)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
/**
 * Update and Select user contractor to winner
 * @param {String} bookingId The bookingId for update
 * @param {String} contractorId The Winner contractor Id
 */
export const acceptSuggestion = async (bookingId, contractorId) => {
  try {
    const res = await fetch.patch(
      `bookings/${bookingId}/users/${contractorId}`
    );
    const elementShow = document.getElementById("response-selecting");
    if (res.data.status === "success") {
      showAlert("success", "The contractor is successfuly selected.", {
        msgType: "block",
        position: "center",
        elementShow,
      });
      window.setTimeout(() => {
        location.assign(`/service-suggestions`);
      }, 3000);
    }
  } catch (err) {
    const elementShow = document.getElementById("response-selecting");
    showAlert("danger", err.response.data.message, {
      msgType: "block",
      position: "center",
      elementShow,
    });
  }
};

export const showPaymentConditions = async () => {
  const province = document.getElementById("province").textContent;
  const tax = await taxtValue(province);
  const { PST, GST } = tax.taxValue;
  const currency = tax.currency;
  const conditions = document.querySelectorAll(".paymentConditions");
  const paymentConditions = JSON.parse(
    conditions[conditions.length - 1].dataset.conditions
  );
  document.getElementById("paymentConditions").innerHTML = `
      <ul>
        <li>
          <div class='row'>
            <div class='col-6 col-md-6'>Title:</div>
            <div class='col-6 col-md-6'>There is no request for accepting.</div>
          </div>
        </li>
      </ul>
      `;
  paymentConditions.forEach((el) => {
    if (el.status === "requested") {
      const subtotal = el.price;
      const totalPrice =
        Math.round(((100 + PST + GST) / 100) * subtotal * 100) / 100;
      document.getElementById("paymentConditions").innerHTML = `
      <ul>
        <li>
          <div class='row'>
            <div class='col-6 col-md-6' id='payment-request-id' data-payment-id='${
              el._id
            }' >Title:</div>
            <div class='col-6 col-md-6'>${el.name}</div>
          </div>
        </li>
        <li>
          <div class='row'>
            <div class='col-6 col-md-6'>Price:</div>
            <div class='col-6 col-md-6'>$ ${currencyFormat(el.price)}</div>
          </div>
        </li>
      </ul>
      <hr>
      <ul>
        <li>
          <div class='row'>
            <div class='col-6 col-md-6'>PST (${PST} %):</div>
            <div class='col-6 col-md-6'>$ ${currencyFormat(
              (PST * subtotal) / 100
            )}</div>
          </div>
        </li>
        <li>
          <div class='row'>
            <div class='col-6 col-md-6'>GST (${GST} %):</div>
            <div class='col-6 col-md-6'>$ ${currencyFormat(
              (GST * subtotal) / 100
            )}</div>
          </div>
        </li>
      </ul>
      <hr>
      <ul>
        <li>
          <div class='row'>
            <div class='col-6 col-md-6'>TOTAL in (${currency}):</div>
            <div class='col-6 col-md-6' id='total-price'>$ ${currencyFormat(
              totalPrice
            )}</div>
          </div>
        </li>
      </ul>
      `;
      document.getElementById("accept-request").disabled = false;
    }
  });
};

export const acceptPaymentRequest = async (
  bookingId,
  contractorId,
  paymentId
) => {
  const elementShow = document.getElementById("response-selecting");
  try {
    const res = await fetch.patch(
      `bookings/${bookingId}/contractor/${contractorId}/paymentConditions/${paymentId}`,
      {
        status: "accepted",
      }
    );
    if (res.data.status === "success") {
      showAlert("success", "The payment request is successfuly accepted.", {
        msgType: "block",
        position: "center",
        elementShow,
      });
      window.setTimeout(() => {
        location.assign(`/service-process`);
      }, 3000);
    }
  } catch (err) {
    showAlert("danger", err.response.data.message, {
      msgType: "block",
      position: "center",
      elementShow,
    });
  }
};
