import { version } from "../../../package.json";
import { COMPANY_NAME } from "../config";

export default class Element {
  constructor() { }
  createOTP = () => {
    const otp = document.createElement("div");
    otp.setAttribute("id", "otp-code");
    const otpForm = `
    <div class='form-group'>
      <div class='form-group mb-3'>
        <div class='input-group'>
          <div class='input-group-prepend'>
            <span class='input-group-text'>
              <i class='fas fa-mobile-alt'></i>
            </span>
          </div>
          <input id='otp' class='form-control' name='otp' maxLength=7 minLength=7 type='tel' required  placeholder='Enter your confirmation code'>
        </div>
      </div>
    </div>
    <div class='form-group mb-0 text-center'>
        <button class='btn btn-primary btn-block' type='submit'>Submit</button>
    </div>
    `;
    otp.insertAdjacentHTML("afterbegin", otpForm);
    return otp;
  };
  createResendCode = () => {
    const aElement = document.createElement("a");
    const resendCode = document.createElement("p");
    aElement.classList.add("text-center", "mt-2");
    aElement.href = "#";
    aElement.innerText = "Resend Code";
    resendCode.setAttribute("id", "resendCode");
    resendCode.classList.add("mt-2", "mb-0");
    resendCode.appendChild(aElement);

    return resendCode;
  };
  createSigninTraditional = () => {
    const traditional = document.createElement("div");
    traditional.setAttribute("id", "signin-traditional");
    const traditionalForm = `
      <div class='form-group mb-3'>
        <div class='input-group'>
          <div class='input-group-prepend'>
            <span class='input-group-text'>
              <i class='fas fa-user-alt'></i>
            </span>
          </div>
          <input id='emailaddress' class='form-control' type='email' required placeholder='Enter your email'>
        </div>
      </div>
      <div class='form-group mb-3'>
        <div class='input-group'>
          <div class='input-group-prepend'>
            <span class='input-group-text'>
              <i class='fas fa-lock'></i>
            </span>
          </div>
          <input id='password' class='form-control' type='password' required placeholder='Enter your password'>
        </div>
      </div>
      <div class='form-group mb-3'>
        <div class='custom-control custom-checkbox'>
          <input id='checkbox-signin' class='custom-control-input' type='checkbox' checked>
          <label class='custom-control-label' for='checkbox-signin'>Remember me</label>
        </div>
      </div>
      <div class='form-group mb-0 text-center'>
        <button class='btn btn-primary btn-block' type='submit'>Log In</button>
      </div>
    `;
    traditional.insertAdjacentHTML("afterbegin", traditionalForm);
    return traditional;
  };
  createSigninMobile = () => {
    const mobile = document.createElement("div");
    mobile.setAttribute("id", "signin-mobile");
    const mobileForm = `
      <div class='form-group mb-3'>
        <div class='input-group'>
          <div class='input-group-prepend'>
            <span class='input-group-text'>
              <i class='fas fa-mobile-alt'></i>
            </span>
          </div>
          <input id='mobile' class='form-control' name='mobile' type='tel' required  placeholder='Enter your mobile number'>
        </div>
        <small class='text-muted'>example: +1(123)456-78-90</small>
      </div>
      <div class='form-group mb-0 text-center'>
        <button class='btn btn-primary btn-block' type='submit' data-toggle='modal' data-target=''>Log In</button>
      </div>
    `;
    mobile.insertAdjacentHTML("afterbegin", mobileForm);
    return mobile;
  };
  createForgetPassword = () => {
    const forget = document.createElement("p");
    forget.setAttribute("id", "forget-password");
    const forgetForm = `
      <a class='text-muted ml-1' href='/forgetpassword'>
        <i class='fa fa-lock mr-1'></i>Forgot your password?
      </a>
    `;
    forget.insertAdjacentHTML("afterbegin", forgetForm);
    return forget;
  };
  /**
   * Clear all contents of your form.
   * @param {Element} element The FORM element that you want to clear content
   */
  clearForm = (element) => {
    element.innerHTML = "";
  };
  /**
   * Create the spinner button.
   * @param {String} name The name of button after spinner appear.
   * @param {("btn-block"|"none")} btnDisplay Display of button.
   * @param {{type:("button"|"a"), color:("primary"|"secondary"|"success"|"info"|"danger"|"pink"), class:("float-left"|"float-right") }} Option The Option for create button
   */
  createSpinnerBtn = (
    name,
    btnDisplay,
    Option = { type: "button", color: "primary", class: "" }
  ) => {
    const button =
      Option.type === "button"
        ? document.createElement("button")
        : document.createElement("a");
    if (Option.type === "button") {
      button.setAttribute("type", "button");
      button.setAttribute("disabled", "true");
    }
    if (Option.type === "a") {
      button.setAttribute("href", "#");
      button.classList.add("disabled");
    }
    button.classList.add(
      "btn",
      `btn-${Option.color}`,
      `${btnDisplay === "none" ? "none" : "btn-block"}`,
      `${Option.class}`
    );
    const span = `
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        ${name}
    `;
    button.insertAdjacentHTML("afterbegin", span);
    return button;
  };
  createCopyright = () => {
    const date = new Date();
    console.log(COMPANY_NAME);
    const markup = `<span>© ${COMPANY_NAME} Co. Ltd. 2019-${date.getFullYear()}. v${version}</span>`;
    return document
      .getElementById("copyright")
      .insertAdjacentHTML("afterbegin", markup);
  };
  /**
   * @param {HTMLElement} selectedParagraph The selected element that you want to add
   * @param {String} content The content that you want to show
   * @param {{position: ("beforeend"|"beforebegin"|"afterend"|"afterbegin")}}  Option selected element that you want to add
   */
  createSpinnerPragraph = (
    selectedParagraph,
    content,
    Option = { position: "beforeend" }
  ) => {
    const pElement = selectedParagraph;
    const oldText = pElement.textContent;
    const newText = `${content} ... `;
    const span =
      '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
    pElement.textContent = newText;
    pElement.insertAdjacentHTML(Option.position, span);
    return oldText;
  };
}
